import withRoot from "../utils/withRoot";
import React from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/styles/withStyles";
import { fade } from "@material-ui/core/styles";
import SearchBox from "../components/SearchBox";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { MaterialUi } from "mdi-material-ui";
import Logo from "../components/Logo";
import SEO from "../components/SEO";
import Page from "../components/Page";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import { makeStyles } from "@material-ui/core/styles";
import Highlighter from "react-highlight-words";

import Divider from "@material-ui/core/Divider";
import SearchBar from "material-ui-search-bar";

import qs from "qs";

const useStyles = makeStyles(theme => ({
  listroot: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
  },
  listinline: {
    display: "inline",
    // width: 300,
  },
  highlighter: {
    width: "auto",
  },
}));

const AlignItemsList = props => {
  const classes = useStyles();

  return (
    <List className={classes.listroot}>
      {props.results.length > 0 ? (
        props.results.map(result => {
          let id = 0;
          return (
            <div>
              <ListItem alignItems="flex-start" key={id++}>
                <ListItemText
                  primary={<Link to={result.path}>{result.title}</Link>}
                  secondary={
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.listinline}
                      color="textPrimary"
                    >
                      <Highlighter
                        highlightClassName={classes.highlighter}
                        searchWords={result.terms}
                        autoEscape={true}
                        textToHighlight={result.content.replace(
                          new RegExp("<mark>|</mark>", "g"),
                          ""
                        )}
                      />
                    </Typography>
                  }
                />
              </ListItem>
              <Divider component="li" />
            </div>
          );
        })
      ) : (
        <Typography>
          {props.searchPressed == true ? (
            <div>当前关键词「{props.q}」无结果展示，请输入其他关键词</div>
          ) : (
            <div></div>
          )}
        </Typography>
      )}
    </List>
  );
};

class Search extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      q: "",
      searchPressed: false,
      shownSearchReresults: [],
    };
  }

  // updateQueryParameters = (key, value) => {
  //   let currentUrlParams = new URLSearchParams(window.location.search);
  //   currentUrlParams.set(key, value);
  //   console.log(currentUrlParams.toString(), key, value);
  //   this.props.history.push(
  //     window.location.pathname + "?" + currentUrlParams.toString()
  //   );
  // };

  // componentDidUpdate(prevProps, prevState) {
  //   let { q } = this.state;

  //   //update query parameters
  //   if (prevState.q !== q) {
  //     this.updateQueryParameters("q", q);
  //   }
  // }

  componentDidMount = () => {
    const { location } = this.props;
    console.log(location);
    let params = qs.parse(location.search);
    console.log(params["?q"]);
    if (params.q !== undefined) {
      this.setState({
        q: params.q,
      });
      this.handleSearch(params.q);
    } else if (params["?q"] !== undefined) {
      this.setState({
        q: params["?q"],
      });
      this.handleSearch(params["?q"]);
    } else {
      console.log("not found");
    }

    if (params["code"] !== undefined) {
      console.log(params["code"]);
    }
  };

  handleSearch = q => {
    if (q == "") {
      return;
    }

    fetch("https://kids-abc.cn/api/search?q=" + q)
      .then(resp => resp.json())
      .then(resp => {
        let final_result = resp.hits.map(hit => {
          let items = hit.id.split("*#*");
          return {
            title: items[0],
            path: items[1],
            score: hit.score,
            terms: hit.fragments[0].terms,
            content: hit.fragments[0].fragments[0],
          };
        });
        console.log(final_result);
        if (final_result != this.state.shownSearchReresults) {
          this.setState({
            shownSearchReresults: final_result,
            searchPressed: true,
          });
        }
      })
      .catch(error => {
        console.log(error);
        console.log("查找失败!");
      });
  };

  handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  handleCheck = value => {
    console.log(value);
  };

  render() {
    const { classes } = this.props;
    return (
      <Page title="">
        <SEO title="搜索">
          <meta content="搜索常用的英语启蒙短语" name="description" />
        </SEO>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <Grid item>
            <SearchBar
              onRequestSearch={this.handleSearch}
              value={this.state.q}
              onChange={value => {
                this.setState({ q: value, searchPressed: false });
              }}
              onCancelSearch={() => {
                this.setState({
                  q: "",
                  shownSearchReresults: [],
                  searchPressed: false,
                });
              }}
              style={{
                margin: "0 auto",
                maxWidth: "100%",
              }}
            />
          </Grid>
          <Grid item>
            <AlignItemsList
              results={this.state.shownSearchReresults}
              q={this.state.q}
              searchPressed={this.state.searchPressed}
            />
          </Grid>
        </Grid>
      </Page>
    );
  }
}

export default withRoot(Search);
